<template>
  <div>
    <div v-if="! isModal" class="level is-variable bd-klmn-columns is-1 is-marginless">
      <div class="level-left">
        <b-button
          class="filter-button is-hidden-mobile"
          size="is-circle"
          icon-left="filter-remove"
          @click="clearFilter"
        />
        <div class="filter-tags">
          <b-tag
            v-for="(value, index) in filters"
            :key="index"
            type="is-purble"
            closable
            @close="removeFilter(value)"
          >
            {{ $t('filter.' + value) }}
          </b-tag>
        </div>
      </div>
      <div class="level-right">
        <slot name="header-right" />
      </div>
    </div>
    <b-table
      :data="data"
      :loading="isLoading"

      :current-page.sync="page"
      :total="total"
      :per-page="perPage"

      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      striped
      sticky-header
      paginated
      hoverable
      backend-sorting
      backend-pagination

      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"

      class="no-wrap"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-table-column v-slot="props" custom-key="actions" width="110">
        <b-button
          outlined
          size="is-small"
          class="button-purble btn-details"
          tag="router-link"
          :to="{ name: 'shifts.edit', params: { id: props.row.id }}"
          icon-left="arrow-right-bold"
        >
          {{ $t('edit') }}
        </b-button>
      </b-table-column>

      <b-table-column :label="$t('filter.id')" field="id" number sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="id-search search-dropdown" :close-on-click="false" trap-focus>
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="queryData.id" :placeholder="$t('idSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          #{{ props.row.id }}
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.shift_name')" field="name" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="queryData.name" :placeholder="$t('addressNameSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <b-tooltip
            :label="props.row.name"
            :active="props.row.name.length > 20"
            type="is-dark"
            position="is-bottom"
          >
            {{ props.row.name | limitShow }}
          </b-tooltip>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.starts_at')" field="starts_at" sortable date centered>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="date-search search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item custom paddingless aria-role="menu-item" :focusable="false">
              <b-datepicker
                v-model="queryData.starts_at"
                :placeholder="$t('dateSearch')"
                :first-day-of-week="1"
                :month-names="$t('dateTimePicker.monthNames')"
                :day-names="$t('dateTimePicker.dayNames')"
                icon="calendar-today"
                range
                inline
              >
                <div class="button is-danger" @click="queryData.starts_at = []">
                  {{ $t('clear') }}
                </div>
              </b-datepicker>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <small class="tag is-abbr-like" :title="props.row.starts_at">
            {{ props.row.starts_at | formatDate }}
          </small>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.ends_at')" field="ends_at" sortable date centered>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="date-search search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item custom paddingless aria-role="menu-item" :focusable="false">
              <b-datepicker
                v-model="queryData.ends_at"
                :placeholder="$t('dateSearch')"
                :first-day-of-week="1"
                :month-names="$t('dateTimePicker.monthNames')"
                :day-names="$t('dateTimePicker.dayNames')"
                icon="calendar-today"
                range
                inline
              >
                <div class="button is-danger" @click="queryData.ends_at = []">
                  {{ $t('clear') }}
                </div>
              </b-datepicker>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <small class="tag is-abbr-like" :title="props.row.ends_at">
            {{ props.row.ends_at | formatDate }}
          </small>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.created_at')" field="created_at" sortable date centered>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="date-search search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item custom paddingless aria-role="menu-item" :focusable="false">
              <b-datepicker
                v-model="queryData.created_at"
                :placeholder="$t('dateSearch')"
                :first-day-of-week="1"
                :month-names="$t('dateTimePicker.monthNames')"
                :day-names="$t('dateTimePicker.dayNames')"
                icon="calendar-today"
                range
                inline
              >
                <div class="button is-danger" @click="queryData.created_at = []">
                  {{ $t('clear') }}
                </div>
              </b-datepicker>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <small class="tag is-abbr-like" :title="props.row.created_at">
            {{ props.row.created_at | formatDate }}
          </small>
        </template>
      </b-table-column>

      <template slot="bottom-left">
        <div v-if="total > 0" class="has-text-left subtitle help">
          {{ $t('paginationShowing', [total.toLocaleString(), ((page * perPage) - perPage) + 1, ((page * perPage) > total ? total : (page * perPage)) ]) }}
        </div>
      </template>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>{{ $t('fetching') }}...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="table" size="is-large" />
            </p>
            <p>{{ $t('nodata') }}</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import debounce from 'lodash/debounce'
import download from 'downloadjs'
import $ from 'jquery'

export default {
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      isLoading: false,
      perPage: 20,
      total: 0,
      page: 1,
      count: 0,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      inputTrigger: null,
      data: [],
      queryData: {
        id: null,
        name: '',
        starts_at: [],
        ends_at: [],
        created_at: [],
        excel: 0,
      },
      filters: [],
    }
  },
  watch: {
    query () {
      this.filterAction()
    },
    queryData: {
      handler () {
        this.filterAction()
      },
      deep: true,
    },
    'queryData.starts_at' () {
      setTimeout(() => $('body').trigger('click'), 250)
    },
    'queryData.ends_at' () {
      setTimeout(() => $('body').trigger('click'), 250)
    },
    'queryData.created_at' () {
      setTimeout(() => $('body').trigger('click'), 250)
    },
    'queryData.id' (n) {
      if (n.indexOf('#') > -1) {
        this.queryData.id = n.replace('#', '');
      }
    },
    boardRefresh (n) {
      if (n == true) {
        this.loadTransfers()
      }
    }
  },
  mounted () {
    this.isLoading = true
    this.fetchShifts()
  },
  methods: {
    filterAction: debounce(function () {
      this.filterShow()
      this.fetchShifts()
    }, 350),
    clearFilter () {
      this.queryData = {
        id: null,
        starts_at: [],
        ends_at: [],
        created_at: [],
        excel: 0,
      }
      this.filterShow()
    },
    filterShow () {
      this.filters = this.$_.keys(this.$_.omitBy(this.queryData, this.$_.isEmpty))
    },
    removeFilter (filter) {
      this.queryData[filter] = this.$_.isArray(this.queryData[filter]) ? [] : ''
      this.filterShow()
    },
    fetchShifts () {
      const params = {
        ...this.queryData,
        sort_by: this.sortField,
        sort_order: this.sortOrder,
        page: this.page,
        per_page: this.perPage,
        created_at: this.queryData.created_at.length === 2
          ? [moment(this.queryData.created_at[0]).format('YYYY-MM-DD'), moment(this.queryData.created_at[1]).format('YYYY-MM-DD')]
          : null,
      }

      const responseType = this.queryData.excel ? 'blob' : 'json'

      this.$http({
        url: '/shifts',
        method: 'GET',
        params,
        responseType,
      }).then(({ data }) => {
        if (this.queryData.excel) {
          const fileName = `shifts-${moment().format('DD-MM-YYYY-h-mm')}.xlsx`
          download(data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          this.$emit('excelChange', false)
          this.$emit('loadingChange', false)
          this.queryData.excel = 0
          return
        }

        this.data = data.data

        this.perPage = data.meta.per_page ?? this.perPage
        this.total = data.meta.total ?? 0
        this.page = data.meta.current_page ?? this.page
        this.count = data.meta.to ?? this.count

        this.$nextTick(() => {
          this.$emit('loadingChange', false)
          this.$emit('excelChange', false)
          this.$store.commit('boardRefresh', false)
          this.jqInputTrigger()
        })
      }).catch((e) => {
        this.data = []
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    onPageChange (page) {
      this.page = page
      this.fetchShifts()
    },
    onSort (field, order) {
      this.page = 1
      this.sortField = field
      this.sortOrder = order
      this.fetchShifts()
    },
    jqInputTrigger () {
      if (this.inputTrigger) {
        return
      }

      $('.dropdown-trigger').click(function (e) {
        e.stopPropagation()
        $(this).parent().find('input, select').first().focus()
      })
      $('.search-dropdown input').keypress(function (e) {
        if (e.which == '13') {
          $('body').trigger('click')
        }
      })
      $('.dropdown').click(function (e) {
        e.stopPropagation()
      })

      this.inputTrigger = true
    },
  },
}
</script>
