<template>
  <div class="content-area">
    <hero-bar>
      {{ $t('shifts') }}
      <b-button
        v-if="checkPermission('shift_create')"
        id="new-address"
        slot="right"
        tag="router-link"
        :to="{ name: 'shifts.create'}"
        icon-left="plus-circle"
        type="is-link"
      >
        {{ $t('createShift') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced bg-none">
        <shifts-table />
      </card-component>
    </section>
  </div>
</template>

<script>
import ShiftsTable from '@/components/ShiftsTable'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'

export default {
  title: ({ $i18n }) => $i18n.t('shifts'),
  components: {
    HeroBar,
    CardComponent,
    ShiftsTable,
  },
}
</script>
